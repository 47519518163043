// require('dotenv').config();

export const PAGE_STATUS_WAITING_FOR_CONNECTION = 0;
export const PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK = 1;
export const PAGE_STATUS_PLACING_OBJECT = 2;
export const PAGE_STATUS_PLACING_TEST1A = 3;
export const PAGE_STATUS_PLACING_TEST1B = 4;
export const PAGE_STATUS_PLACING_TEST2 = 5;
export const PAGE_STATUS_PLACING_TEST3 = 6;
export const PAGE_STATUS_PLACING_TEST4 = 7;

export const POLLING_INTERVAL = 500; //1000

export const ROUTES_PC_CONNECTION = "/connection";
export const ROUTES_PC_STARTED_SESSION = "/startsession";

//export const URL_DOMAIN = 'http://eyetesters.com:8443';
//export const QR_DOMAIN = 'http://eyetesters.com';
export const URL_DOMAIN = process.env.REACT_APP_API;
export const QR_DOMAIN = process.env.REACT_APP_QR_DOMAIN;
// export const QR_DOMAIN = 'http://' + process.env.REACT_APP_API_IP;

export const URL_ROUTE_USER = URL_DOMAIN + "/user";

export const KNOW_SPECS_YES = "1";
export const KNOW_SPECS_NO = "2";

export const WEARING_YES = 1;
export const WEARING_NO = 2;

export const GLASSES = 1;
export const CONTACTS = 2;
export const NO_EYE_WEAR = 3;

export const MYOPIC = 1;
export const HYPEROPIC = 2;
export const MIXED = 3;
export const UNKNOWN = 4;

// Order of Qs, matters on page 12
export const Q_ARR = [
  "Answer1a",
  "Answer1b",
  "VAscOD",
  "VAscOS",
  "PrimeDrPOVOD",
  "PrimeDrPOVOS",
  "VAaOD",
  "VAaOS",
  "VAccOD",
  "VAccOS",
];

export const CHANGE_INTS = [50, 20, 5, 1];
// export const CHANGE_INTS = [50,20,5,2,1]
// export const CHANGE_INTS = [50,1]
export const TD = 3.048; // test distance (in meters) 10ft==3.048m

// keep first axis as 0!!
// export const const Q3_ORIENTATIONS = [0, 75, 36, 78, 18, 66, 27, 21, 63, 39, 30, 9, 87, 51, 81, 84, 54, 33, 3, 45, 12, 15, 72, 42, 69, 24, 57, 48, 60, 6];
export const Q3_ORIENTATIONS = [
  0, 25, 75, 45, 15, 65, 5, 85, 40, 60, 80, 55, 30, 50, 10, 35, 20, 70,
];
// export const Q3_ORIENTATIONS = [0, 25, 75, 45, 15, 65, 5, 85, 40, 60, 80];
// export const Q3_ORIENTATIONS = [0,15,30,45,60,75];
// export const Q3_ORIENTATIONS = [0,30,60];
// export const Q3_ORIENTATIONS = [0];

export const INITVA = 218.5;
export const VA_SIZES = [
  200, 160, 125, 100, 80, 67, 50, 40, 33, 25, 20, 16.7, 12.5, 10
];

// multiplier for axis test
export const VA_MULTIPLIER = 4.5;
export const MAX_AXIS_VA = 400;

// export const IS_CONTINUE_YES = 1;
// export const IS_CONTINUE_NO = 2;
